import React from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch } from 'store/redux/hooks';
import { initializeAuthStateActionAsync } from 'store/redux/modules/user';

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];

    React.useEffect(() => {
        dispatch(initializeAuthStateActionAsync(locale as 'fr' | 'en'));
    }, [dispatch, locale]);
    return <>{children}</>;
};
