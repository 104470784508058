import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { AxiosResponse } from 'axios';
import { getRecaptchaToken } from 'hooks/useRecaptchaScript/useRecaptchaScript';

import { restAPI } from 'services/api';
import { queryClient } from 'services/client';
import { IApplicationMeta, IUserState } from 'store/redux/modules/user/types';
import { EErrorResult } from 'utils/constants';

import { IInitOptions } from './types';

export const sacInit = async (options: IInitOptions): Promise<AxiosResponse<any> | null> => {
    try {
        const token = await getRecaptchaToken();
        return await queryClient.fetchQuery({
            queryFn: async () =>
                await restAPI.post('/sac/init', {
                    cashierId: options.cashierId,
                    channel: options.channel,
                    language: options.language,
                    offerCode: options.offerCode,
                    otherParams: options.otherParams,
                    requestChannel: options.requestChannel,
                    storeNum: options.storeNum,
                    token,
                    vendorCode: options.vendorCode,
                }),
            queryKey: ['sacInit'],
        });
    } catch (err) {
        TrackJS.track(err!);
        throw err;
    }
};

export const sacContinue = async ({ continueToken }: { continueToken: string }): Promise<AxiosResponse<any> | null> => {
    let response: AxiosResponse<any> | null = null;

    try {
        response = await queryClient.fetchQuery({
            queryFn: async () => await restAPI.post('/sac/continue', { continueToken }),
            queryKey: ['sacContinue'],
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const sacUnsubscribe = async (token: string): Promise<AxiosResponse<any> | null> => {
    let response: AxiosResponse<any> | null = null;

    try {
        response = await queryClient.fetchQuery({
            queryFn: async () => await restAPI.post('/sac/unsubscribe', { token }),
            queryKey: ['sacUnsubscribe'],
        });
        return response;
    } catch (error: any) {
        TrackJS.track(error);
        throw error;
    }
};

export const sacSave = async (body: {
    applicant?: Partial<IUserState['applicant']>;
    metaData?: Partial<IApplicationMeta>;
}): Promise<AxiosResponse<any>> => {
    try {
        return await queryClient.fetchQuery({
            queryFn: async () => await restAPI.post('/sac/save', body),
            queryKey: ['sacSave'],
        });
    } catch (error: any) {
        TrackJS.track(error);
        throw error;
    }
};

export const sacExit = async (applicant: Partial<IUserState['applicant']>): Promise<AxiosResponse<any>> => {
    try {
        return await queryClient.fetchQuery({
            queryFn: async () => await restAPI.post('/sac/exit', { applicant: applicant }),
            queryKey: ['sacExit'],
        });
    } catch (error: any) {
        TrackJS.track(error);
        throw new Error(error?.response?.data?.error || EErrorResult.ERROR);
    }
};

export const sacResume = async (continueToken: string): Promise<AxiosResponse<any> | null> => {
    let response: AxiosResponse<any> | null = null;

    try {
        response = await queryClient.fetchQuery({
            queryFn: async () => await restAPI.post('/sac/resume', { continueToken }),
            queryKey: ['sacResume'],
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const sacAssociateAuth = async (unauthId: null | string): Promise<AxiosResponse<any> | null> => {
    let response: AxiosResponse<any> | null = null;
    try {
        response = await queryClient.fetchQuery({
            queryFn: async () => await restAPI.post('/sac/associate-auth', { unauthId }),
            queryKey: ['sacAssociateAuth'],
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};
