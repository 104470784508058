import React, { useEffect } from 'react';
import { useRouteObserver } from '@fairstone/ui/core/components/utils/RouteObserver';
import { useMediaQuery } from '@fairstone/ui/core/utils/media/useMediaQuery';
import useHomePage from 'hooks/useHomePage/useHomePage';
import useRecaptchaScript from 'hooks/useRecaptchaScript/useRecaptchaScript';
import { useScrollToTop } from 'hooks/useScrollToTop/useScrollToTop';

import { A11yAnnoncer } from 'components/A11yAnnoncer';
import { Footer } from 'components/Footer/walmart';
import { Header } from 'components/Header/walmart';
import { LandingHeader } from 'components/LandingHeader';
import { ProgressBar } from 'components/ProgressBar';
import { ThreatMetrix } from 'components/Threatmx';
import { Content } from 'layouts/Content';
import { useAppDispatch } from 'store/redux/hooks';
import { setIDVerificationFlow } from 'store/redux/modules/user';
import { ERoutes } from 'utils/constants';

import styles from './App.module.scss';

export const AppLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
    useScrollToTop();
    const dispatch = useAppDispatch();
    const { isHomePage } = useHomePage();

    const isDesktop = !useMediaQuery('tab-port');
    const isFooterVisible = (): boolean => isHomePage || isDesktop;

    useRecaptchaScript();
    useRouteObserver();

    useEffect(() => {
        // Hijack Back button
        window.onpopstate = () => {
            if (document.location.pathname === ERoutes.APP_START) {
                dispatch(setIDVerificationFlow(null));
            }
        };
        return () => {
            window.onpopstate = () => null;
        };
    }, [dispatch]);

    return (
        <div className={styles.layout}>
            <A11yAnnoncer />
            <ThreatMetrix />
            {isHomePage ? <LandingHeader /> : <Header className={styles.header} />}
            <ProgressBar className={styles.progress} />
            <Content className={`${styles.content}`}>{children}</Content>
            {isFooterVisible() && <Footer className={styles.footer} />}
        </div>
    );
};
