import React from 'react';
import Helmet from 'react-helmet';
import useScript from 'hooks/useScript/useScript';

import { PUBLIC, THREAT_METRIX_ORG_ID, THREAT_METRIX_URL } from 'config/constants';
import { useAppSelector } from 'store/redux/hooks';
import { sessionState } from 'store/redux/modules/session';

// TODO: Enhance this type: tmx.go()
declare let tmx: any;
export const ThreatMetrix = (): React.ReactElement => {
    const { sessionId } = useAppSelector(sessionState);
    const [loaded] = useScript(`${PUBLIC}/assets/scripts/hoiaewhgoi.js`);

    React.useEffect(() => {
        const onLoad = () => tmx.go(THREAT_METRIX_URL, THREAT_METRIX_ORG_ID, sessionId);

        if (loaded === true) {
            onLoad();
        }
    }, [loaded, sessionId]);

    return (
        <Helmet>
            <noscript>
                {`<iframe title="threat-metrix" style="width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;" sandbox="allow-same-origin" src="https://${THREAT_METRIX_URL}/fp/tags?org_id=${THREAT_METRIX_ORG_ID}&session_id=${sessionId}></iframe>`}
            </noscript>
        </Helmet>
    );
};
