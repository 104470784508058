import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { translate } from '@fairstone/ui/core/utils/translate';

import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { setA11yMessage } from 'store/redux/modules/a11y';
import { selectA11yMessage } from 'store/redux/modules/a11y/selectors';
import { getPageTitle } from 'utils/url/pageTitle';

import styles from './A11yAnnoncer.module.scss';

export const A11yAnnoncer: React.FC = (): React.ReactElement => {
    const message = useAppSelector(selectA11yMessage);
    const dispatch = useAppDispatch();
    const location = useLocation();

    useEffect(() => {
        const pageTitle = getPageTitle(location.pathname);
        const navigateMessage = `${translate('a11y.message')} ${translate(pageTitle)}`;

        dispatch(setA11yMessage(navigateMessage));
    }, [dispatch, location]);

    return (
        <div
            aria-atomic="true"
            aria-live="assertive"
            className={styles.a11yMessage}
            data-testid="a11y-id"
            role="status"
        >
            {message && <div>{message}</div>}
        </div>
    );
};

export default A11yAnnoncer;
