import { ERoutes } from 'utils/constants';

import { removeTrailingSlash } from './removeTrailingSlash';

const paths: string[] = [
    `/${ERoutes.INVALID_AGE}`,
    `/${ERoutes.ERROR}`,
    `/${ERoutes.NOT_FOUND}`,
    `/${ERoutes.OOPS_ERROR}`,
    `/${ERoutes.MANUAL_UNABLE_TO_VALIDATE}`,
    `/${ERoutes.PRIVACY}`,
    `/${ERoutes.CANCEL_APPLICATION}`,
];

export const hideSaveAndContinueButton = (pathname: string): boolean => {
    //delete the last slash if exist and lowercase
    pathname = removeTrailingSlash(pathname);
    return paths.some((path: string) => pathname.includes(path.toLowerCase()));
};
