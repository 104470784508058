import { TLangTranslations } from '@fairstone/ui/core/providers/Intl';
import { createAsyncThunk } from '@reduxjs/toolkit';

import authService from 'services/auth/auth';
import {
    sacAssociateAuth,
    sacContinue,
    sacExit,
    sacInit,
    sacResume,
    sacSave,
    sacUnsubscribe,
} from 'services/saveAndContinue';
import { IInitOptions } from 'services/saveAndContinue/types';
import { tuSubmit, tuVerify } from 'services/transUnion';
import { wmNewApplication } from 'services/walmart';
import { INewApplicationRequest } from 'services/walmart/types';
import { RootState } from 'store/redux/types';
import { ERequestChannel } from 'utils/constants';
import { getIgnoreKeyCase, removeKeys } from 'utils/query/query';

import { IApplicationMeta, IResumeResponse, IUserState, TStartContinueFlowAction } from './types';
import { load, setContinueToken } from '.';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const clearStateAsync = createAsyncThunk('user/clear-state', async () => {});

export const signInActionAsync = createAsyncThunk('user/sign-in', async (email_or_phone: string) => {
    const username = email_or_phone.match(/^\d{10}$/) ? `+1${email_or_phone}` : email_or_phone;

    const cognitoUser = await authService.signIn(username);
    // return cognitoUser;
    return { username: cognitoUser.getUsername() };
});

export const setUsernameAsync = createAsyncThunk('user/set-username', async (username: string) => {
    authService.setUsername(username);
    return username;
});

export const signUpActionAsync = createAsyncThunk(
    'user/sign-up',
    async ({ email_or_phone, locale }: { email_or_phone: string; locale: TLangTranslations }) => {
        const username = email_or_phone.match(/^\d{10}$/) ? `+1${email_or_phone}` : email_or_phone;
        try {
            await authService.signUp(username, locale);
            await authService.signIn(username);
            return true;
        } catch (err: any) {
            if (err.code === 'UsernameExistsException') {
                await authService.signIn(username);
                return true;
            }
            return Promise.reject(err);
        }
    }
);

export const answerOtpActionAsync = createAsyncThunk('user/answer-otp', async (otp: string) => {
    const isAuthenticated = await authService.answerCustomChallenge(otp);
    if (isAuthenticated) {
        return { isAuthenticated };
    }
    throw new Error('Could not authenticate');
});

export const resendOtpActionAsync = createAsyncThunk('user/resend-otp', async () => {
    await authService.resendOtp();
});

export const signOutActionAsync = createAsyncThunk('user/sign-out', async () => {
    await authService.signOut();
});

export const initializeAuthStateActionAsync = createAsyncThunk(
    'user/initialize',
    async (language: 'en' | 'fr', { dispatch }) => {
        const cognitoUser = await authService.getCurrentUser();
        if (cognitoUser) {
            const isAuthenticated = await authService.isAuthenticated();

            dispatch(load({ isAuthenticated }));
        }
    }
);

export const initializeUnauthStateActionAsync = createAsyncThunk(
    'user/unauth-init',
    async ({ language }: { language: 'en' | 'fr' }, { dispatch, getState }) => {
        const { channel, requestChannel, savedQueryParameters } = (getState() as RootState).router;
        const sessionID = getIgnoreKeyCase(savedQueryParameters, 'sessionID');
        if (requestChannel === ERequestChannel.Walmart && sessionID) {
            try {
                await dispatch(
                    resumeApplicationActionAsync({
                        channel,
                        language,
                        sessionID,
                    })
                ).unwrap();
            } catch (error) {
                throw error;
            }
        }

        const { altFlow, unauthId } = (getState() as RootState).user;

        // Prevent calling init more than once per session
        if (unauthId && altFlow) {
            return Promise.resolve({ altFlow, unauthId });
        }
        const payload: IInitOptions = {
            cashierId: getIgnoreKeyCase(savedQueryParameters, 'cashierid'),
            channel,
            language,
            otherParams: removeKeys(savedQueryParameters, [
                'vendor',
                'storenum',
                'cashierid',
                'requestChannel',
                'channel',
            ]),
            requestChannel: getIgnoreKeyCase(savedQueryParameters, 'requestChannel') ?? 'Online',
            storeNum: getIgnoreKeyCase(savedQueryParameters, 'storenum'),
            vendorCode: getIgnoreKeyCase(savedQueryParameters, 'vendor'),
        };

        if (!channel && !!getIgnoreKeyCase(savedQueryParameters, 'department')) {
            payload.channel = getIgnoreKeyCase(savedQueryParameters, 'department');
        }

        const offerCode = getIgnoreKeyCase(savedQueryParameters, 'offerCode');
        if (offerCode) {
            payload.offerCode = offerCode;
        }

        return sacInit(payload)
            .then(({ data }: any) => data)
            .then(({ altFlow: assignedFlow, uuid: assignedUuid }) => ({
                altFlow: assignedFlow,
                unauthId: assignedUuid,
            }))
            .catch((error) => {
                if (error.response.status === 409) {
                    return dispatch(signOutActionAsync()).then(() =>
                        sacInit(payload)
                            .then(({ data }: any) => data)
                            .then(({ altFlow: assignedFlow2, uuid: assignedUuid2 }) => ({
                                altFlow: assignedFlow2,
                                unauthId: assignedUuid2,
                            }))
                    );
                }
                return Promise.reject(error);
            });
    }
);

export const startContinueFlowActionAsync = createAsyncThunk(
    'user/start-continue-flow',
    async ({ continueToken }: TStartContinueFlowAction, { dispatch }) => {
        try {
            // to start continue flow, user have to be unauthenticated
            if (await authService.isAuthenticated()) {
                await dispatch(signOutActionAsync());
            }
            await dispatch(setContinueToken(continueToken));
            const response: any = await sacContinue({ continueToken });
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                // Logout first
                await dispatch(signOutActionAsync());
                const responseAfterLogout: any = await sacContinue({ continueToken });
                return responseAfterLogout.data;
            }
            throw error;
        }
    }
);

export const unsubscribeEmailActionAsync = createAsyncThunk(
    'user/unsubscribe-email',
    async (token: string) => await sacUnsubscribe(token)
);

export const saveApplicantPropertiesActionAsync = createAsyncThunk(
    'user/save-applicant-properties-sac-save',
    async (payload: Partial<IUserState['applicant']>) => {
        await sacSave({ applicant: payload });
        return payload;
    }
);

export const saveApplicantPropertiesInStateActionAsync = createAsyncThunk(
    'user/save-applicant-properties',
    async (payload: Partial<IUserState['applicant']>) => Promise.resolve(payload)
);

export const saveMetadataPropertiesActionAsync = createAsyncThunk(
    'user/save-metadata-properties',
    async (payload: Partial<IApplicationMeta>) => {
        await sacSave({ metaData: payload });
        return payload;
    }
);

export const saveAndExitActionAsync = createAsyncThunk(
    'user/save-and-exit',
    async ({ locale }: { locale: string }, { getState }) => {
        const { applicant } = (getState() as RootState).user;
        return await sacExit({
            ...applicant,
            languagePreference: typeof locale === 'string' && locale.includes('en') ? 'en' : 'fr',
        });
    }
);

export const verifyApplicantActionAsync = createAsyncThunk('users/verify-applicant', async (_, { getState }) => {
    const { city, dob, firstName, lastName, postal, province, street1, street2 } = (getState() as RootState).user
        .applicant;
    return tuVerify({
        city,
        dob,
        firstName,
        lastName,
        postal,
        province,
        street1,
        street2,
    });
});

export const submitApplicationActionAsync = createAsyncThunk(
    'users/submit-application',
    async (payload: Partial<IUserState['applicant']>, { rejectWithValue }) => {
        try {
            const response = await tuSubmit(payload);
            return response?.data;
        } catch (err: any) {
            if (!err.response) throw err;
            return rejectWithValue(err.response.data);
        }
    }
);

export const resumeApplicationActionAsync = createAsyncThunk<IResumeResponse, INewApplicationRequest | undefined>(
    'user/resume-application',
    async (newAppRequest, { getState, rejectWithValue }) => {
        const { continueToken } = (getState() as RootState).user;
        try {
            if (newAppRequest) {
                const response = await wmNewApplication(newAppRequest);
                if (response?.data) {
                    return response.data;
                }
                throw new Error('Invalid response from wmNewApplication');
            } else if (continueToken) {
                const response = await sacResume(continueToken);
                if (response?.data) {
                    return response.data;
                }
                throw new Error('Invalid response from sacResume');
            } else {
                throw new Error('Missing continueToken');
            }
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.result || error.message || 'An error occurred');
        }
    }
);

export const associateAuthActionAsync = createAsyncThunk('user/associate-auth', async (_, { getState }) =>
    sacAssociateAuth((getState() as RootState).user.unauthId).then(({ data }: any) => data)
);
