import { useEffect } from 'react';
import lazyWithPreload from 'react-lazy-with-preload';
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, useLocation } from 'react-router-dom';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { useNavigateLastSavePoint } from 'hooks/navigate/useNavigateLastSavePoint/useNavigateLastSavePoint';
import getStoredState from 'redux-persist/lib/getStoredState';

import { LargeLayout } from 'layouts/walmart/large/LargeLayout';
import { SmallLayout } from 'layouts/walmart/small/SmallLayout';

const ErrorPage = lazyWithPreload(() => import('pages/Error'));
const AdditionalCardHolderPage = lazyWithPreload(() => import('pages/walmart/AddOns/AdditionalCardHolder'));
const AdditionalCardHolderDetailsPage = lazyWithPreload(
    () => import('pages/walmart/AddOns/AdditionalCardHolderDetails')
);
const InsurancePage = lazyWithPreload(() => import('pages/walmart/AddOns/Insurance'));
const PaperlessPage = lazyWithPreload(() => import('pages/walmart/AddOns/Paperless'));
const CancelApplicationPage = lazyWithPreload(() => import('pages/walmart/CancelApplication'));
const ApprovedPage = lazyWithPreload(() => import('pages/walmart/Confirmation/Approved'));
const DeclinedPage = lazyWithPreload(() => import('pages/walmart/Confirmation/Declined'));
const FinancingPage = lazyWithPreload(() => import('pages/walmart/Confirmation/Financing'));
const PendingApprovalPage = lazyWithPreload(() => import('pages/walmart/Confirmation/PendingApproval'));
const SetUpPage = lazyWithPreload(() => import('pages/walmart/Confirmation/SetUp'));
const SetUpCompletePage = lazyWithPreload(() => import('pages/walmart/Confirmation/SetUpComplete'));
const IncomePage = lazyWithPreload(() => import('pages/walmart/Employment/Income'));
const JobTitlePage = lazyWithPreload(() => import('pages/walmart/Employment/JobTitle'));
const StatusPage = lazyWithPreload(() => import('pages/walmart/Employment/Status'));
const LivingArrangementPage = lazyWithPreload(() => import('pages/walmart/Household/LivingArrangement'));
const PhoneNumberPage = lazyWithPreload(() => import('pages/walmart/Household/PhoneNumber'));
const RentMortgagePage = lazyWithPreload(() => import('pages/walmart/Household/RentMortgage'));
const IDSelectionPage = lazyWithPreload(() => import('pages/walmart/IDSelection'));
const InvalidAgePage = lazyWithPreload(() => import('pages/walmart/InvalidAge'));
const AddressPage = lazyWithPreload(() => import('pages/walmart/Manual/Address'));
const BirthdayPage = lazyWithPreload(() => import('pages/walmart/Manual/Birthday'));
const NamePage = lazyWithPreload(() => import('pages/walmart/Manual/Name'));
const UnableToVerifyPage = lazyWithPreload(() => import('pages/walmart/Manual/UnableToVerify'));
const NotFoundPage = lazyWithPreload(() => import('pages/walmart/NotFound'));
const OnfidoConfirmationPage = lazyWithPreload(() => import('pages/walmart/Onfido/Confirmation'));
const OnfidoVerifyPage = lazyWithPreload(() => import('pages/walmart/Onfido/Verify'));
const OtpAnswerCustomChallengePage = lazyWithPreload(() => import('pages/walmart/Otp/Confirm'));
const OtpFailedPage = lazyWithPreload(() => import('pages/walmart/Otp/Failed'));
const PrivacyPage = lazyWithPreload(() => import('pages/walmart/Privacy'));
const ReviewPage = lazyWithPreload(() => import('pages/walmart/Review'));
const ContinuePage = lazyWithPreload(() => import('pages/walmart/SaveAndContinue/Continue'));
const ResumePage = lazyWithPreload(() => import('pages/walmart/SaveAndContinue/Resume'));
const UnsubscribePage = lazyWithPreload(() => import('pages/walmart/SaveAndContinue/Unsubscribe'));
const OpsErrorPage = lazyWithPreload(() => import('pages/walmart/OpsError'));
const SessionTimeoutPage = lazyWithPreload(() => import('pages/walmart/SessionTimeout'));
const SubmitApplicationPage = lazyWithPreload(() => import('pages/walmart/SubmitApplication'));
const WelcomeConsentPage = lazyWithPreload(() => import('pages/walmart/WelcomeConsent'));
const WhereCanWeReachYouPage = lazyWithPreload(() => import('pages/walmart/WhereCanWeReachYou'));

import authService from 'services/auth/auth';
import { pushRouteChangeDataLayer } from 'services/gtm';
import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { setHasSubmittedApplication, userState } from 'store/redux/modules/user';
import { persistConfig } from 'store/redux/store-persist-config';
import { RootState } from 'store/redux/types';
import { ERoutes, EVerificationPaths } from 'utils/constants';
import { SEOPaths } from 'utils/seo/constants';

import RouteAppLayout from './RouteAppLayout';
import SEOLayout from './SEOLayout';

const deleteLocalStorage = async () => {
    let state: RootState = {} as RootState;
    try {
        state = (await getStoredState(persistConfig)) as RootState;
    } catch (error) {
        TrackJS.track(error!);
    }
    const userState = state?.user;
    if (!userState || (userState.status === 'idle' && !userState.authId && !userState.unauthId)) {
        authService.clearCognito();
        window.localStorage.removeItem('saved_qp');
    }
};

const SubmittedPages = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setHasSubmittedApplication());
    }, [dispatch]);
    return <Outlet />;
};

const PageChange = () => {
    const location = useLocation();

    useEffect(() => {
        pushRouteChangeDataLayer();
    }, [location]);
    return <Outlet />;
};

const FlowVerification = ({
    checkHasSubmittedApplication = false,
    checkHasVerification = false,
    checkUnAuthId = false,
}: {
    checkUnAuthId?: boolean;
    checkHasVerification?: boolean;
    checkHasSubmittedApplication?: boolean;
}) => {
    const user = useAppSelector(userState);
    const { onNavigateLastSavePoint } = useNavigateLastSavePoint();
    const hasVerification = user.manualVerificationResult !== null || user.digitalVerificationSent;
    const { hasSubmittedApplication } = user;

    useEffect(() => {
        if (
            (checkUnAuthId && !user.unauthId) ||
            (checkHasVerification && !hasVerification) ||
            (checkHasSubmittedApplication && hasSubmittedApplication)
        ) {
            onNavigateLastSavePoint();
        }
    }, [
        user,
        hasVerification,
        hasSubmittedApplication,
        checkUnAuthId,
        checkHasVerification,
        checkHasSubmittedApplication,
        onNavigateLastSavePoint,
    ]);

    return <Outlet />;
};

const elements = (
    <Route element={<PageChange />}>
        <Route
            element={
                <RouteAppLayout>
                    <Outlet />
                </RouteAppLayout>
            }
        >
            <Route element={<Navigate replace to="/apply" />} path="/" />
            <Route element={<Navigate replace to="/apply?lang=fr" />} path="/fr" />
            <Route element={<Navigate replace to="/apply?lang=en" />} path="/en" />

            <Route
                element={<Outlet />}
                loader={async () => {
                    await deleteLocalStorage();
                    return null;
                }}
            >
                <Route
                    element={
                        <SEOLayout path={SEOPaths.APPLY}>
                            <Outlet />
                        </SEOLayout>
                    }
                    path="/apply"
                >
                    {/* Application Confirmation section */}
                    <Route
                        element={<SubmittedPages />}
                        loader={(): null => {
                            ApprovedPage.preload();
                            FinancingPage.preload();
                            PendingApprovalPage.preload();
                            SetUpPage.preload();
                            DeclinedPage.preload();
                            return null;
                        }}
                    >
                        <Route element={<ApprovedPage />} path={ERoutes.CONFIRMATION_APPROVED} />
                        <Route element={<FinancingPage />} path={ERoutes.CONFIRMATION_FINANCING} />
                        <Route element={<PendingApprovalPage />} path={ERoutes.CONFIRMATION_PENDING} />
                        <Route element={<SetUpPage />} path={ERoutes.CONFIRMATION_APPROVED_SETUP} />
                        <Route element={<DeclinedPage />} path={ERoutes.CONFIRMATION_DECLINED} />
                    </Route>

                    <Route
                        element={
                            <LargeLayout>
                                <Outlet />
                            </LargeLayout>
                        }
                    >
                        <Route
                            element={<WelcomeConsentPage />}
                            index
                            loader={() => {
                                WhereCanWeReachYouPage.preload();
                                return null;
                            }}
                        />
                    </Route>
                    <Route
                        element={
                            <SmallLayout>
                                <Outlet />
                            </SmallLayout>
                        }
                    >
                        <Route element={<FlowVerification checkUnAuthId={true} />}>
                            <Route
                                element={<WhereCanWeReachYouPage />}
                                loader={() => {
                                    IDSelectionPage.preload();
                                    return null;
                                }}
                                path={ERoutes.WHERE_CAN_WE_REACH_YOU}
                            />
                            <Route
                                element={<IDSelectionPage />}
                                loader={(): null => {
                                    OnfidoVerifyPage.preload();
                                    AddressPage.preload();
                                    return null;
                                }}
                                path={ERoutes.APP_START}
                            />
                            <Route element={<InvalidAgePage />} path={ERoutes.INVALID_AGE} />
                            <Route element={<CancelApplicationPage />} path={ERoutes.CANCEL_APPLICATION} />

                            {/* Digital section */}
                            <Route
                                element={<OnfidoVerifyPage />}
                                loader={() => {
                                    OnfidoConfirmationPage.preload();
                                    return null;
                                }}
                                path={ERoutes.DIGITAL_ONFIDO}
                            />
                            <Route
                                element={<OnfidoConfirmationPage />}
                                loader={(): null => {
                                    InvalidAgePage.preload();
                                    PhoneNumberPage.preload();
                                    UnableToVerifyPage.preload();
                                    return null;
                                }}
                                path={ERoutes.DIGITAL_CONFIRMATION}
                            />
                            <Route
                                element={<UnableToVerifyPage path={EVerificationPaths.ONFIDO} />}
                                path={ERoutes.DIGITAL_UNABLE_TO_VALIDATE}
                            />
                            {/* Manual section */}
                            <Route
                                element={<NamePage />}
                                loader={(): null => {
                                    BirthdayPage.preload();
                                    return null;
                                }}
                                path={ERoutes.MANUAL_NAME}
                            />
                            <Route
                                element={<BirthdayPage />}
                                loader={(): null => {
                                    AddressPage.preload();
                                    return null;
                                }}
                                path={ERoutes.MANUAL_BIRTHDATE}
                            />
                            <Route
                                element={<AddressPage />}
                                loader={(): null => {
                                    PhoneNumberPage.preload();
                                    UnableToVerifyPage.preload();
                                    InvalidAgePage.preload();
                                    return null;
                                }}
                                path={ERoutes.MANUAL_ADDRESS}
                            />
                            <Route
                                element={<UnableToVerifyPage path={EVerificationPaths.MANUAL} />}
                                path={ERoutes.MANUAL_UNABLE_TO_VALIDATE}
                            />

                            <Route element={<FlowVerification checkHasVerification={true} />}>
                                {/* Household section */}
                                <Route
                                    element={<PhoneNumberPage />}
                                    loader={() => {
                                        LivingArrangementPage.preload();
                                        return null;
                                    }}
                                    path={ERoutes.PHONE_NUMBER}
                                />
                                <Route
                                    element={<LivingArrangementPage />}
                                    loader={() => {
                                        RentMortgagePage.preload();
                                        IncomePage.preload();
                                        StatusPage.preload();
                                        return null;
                                    }}
                                    path={ERoutes.LIVING_ARRANGEMENT}
                                />
                                <Route
                                    element={<RentMortgagePage />}
                                    loader={(): null => {
                                        IncomePage.preload();
                                        StatusPage.preload();
                                        return null;
                                    }}
                                    path={ERoutes.RENT_OR_MORTGAGE}
                                />

                                {/* Employment section */}
                                <Route
                                    element={<StatusPage />}
                                    loader={(): null => {
                                        IncomePage.preload();
                                        JobTitlePage.preload();
                                        return null;
                                    }}
                                    path={ERoutes.EMPLOYMENT_STATUS}
                                />
                                <Route
                                    element={<IncomePage />}
                                    loader={(): null => {
                                        PaperlessPage.preload();
                                        ReviewPage.preload();
                                        return null;
                                    }}
                                    path={ERoutes.INCOME}
                                />
                                <Route
                                    element={<JobTitlePage />}
                                    loader={(): null => {
                                        IncomePage.preload();
                                        return null;
                                    }}
                                    path={ERoutes.JOB_TITLE}
                                />

                                {/* AddOns section */}
                                <Route
                                    element={<PaperlessPage />}
                                    loader={(): null => {
                                        AdditionalCardHolderPage.preload();
                                        return null;
                                    }}
                                    path={ERoutes.PAPERLESS}
                                />

                                <Route
                                    element={<ReviewPage />}
                                    loader={(): null => {
                                        PrivacyPage.preload();
                                        return null;
                                    }}
                                    path={ERoutes.REVIEW}
                                />

                                <Route
                                    element={<PrivacyPage />}
                                    loader={(): null => {
                                        SubmitApplicationPage.preload();
                                        return null;
                                    }}
                                    path={ERoutes.PRIVACY}
                                />

                                {/* Submit application */}
                                <Route element={<FlowVerification checkHasSubmittedApplication={true} />}>
                                    <Route
                                        element={<SubmitApplicationPage />}
                                        loader={(): null => {
                                            ApprovedPage.preload();
                                            FinancingPage.preload();
                                            PendingApprovalPage.preload();
                                            SetUpPage.preload();
                                            DeclinedPage.preload();
                                            return null;
                                        }}
                                        path={ERoutes.SUBMIT_APPLICATION}
                                    />
                                </Route>
                            </Route>
                        </Route>

                        {/* OTP section */}
                        <Route
                            element={<OtpAnswerCustomChallengePage />}
                            loader={(): null => {
                                OtpFailedPage.preload();
                                IDSelectionPage.preload();
                                ResumePage.preload();
                                return null;
                            }}
                            path={ERoutes.CONFIRM_OTP}
                        />
                        <Route
                            element={<OtpFailedPage />}
                            loader={(): null => {
                                IDSelectionPage.preload();
                                return null;
                            }}
                            path={ERoutes.OTP_FAILED}
                        />
                        {/* Save & Continue */}
                        <Route
                            element={<ContinuePage />}
                            loader={(): null => {
                                OtpAnswerCustomChallengePage.preload();
                                return null;
                            }}
                            path={ERoutes.SAC_CONTINUE}
                        />
                        <Route
                            element={<ResumePage />}
                            loader={(): null => {
                                PendingApprovalPage.preload();
                                ApprovedPage.preload();
                                SetUpPage.preload();
                                IDSelectionPage.preload();
                                PhoneNumberPage.preload();
                                LivingArrangementPage.preload();
                                RentMortgagePage.preload();
                                StatusPage.preload();
                                JobTitlePage.preload();
                                IncomePage.preload();
                                PaperlessPage.preload();
                                ReviewPage.preload();
                                return null;
                            }}
                            path={ERoutes.SAC_RESUME}
                        />
                        <Route element={<UnsubscribePage />} path={ERoutes.SAC_UNSUBSCRIBE} />
                        {/* Confirmation section */}
                        <Route element={<SubmittedPages />}>
                            <Route element={<SetUpCompletePage />} path={ERoutes.CONFIRMATION_SETUP_COMPLETE} />
                        </Route>

                        {/* Session Timeout */}
                        <Route element={<SessionTimeoutPage />} path={ERoutes.SESSION_TIMEOUT} />
                    </Route>

                    {/* AddOns section */}
                    <Route element={<FlowVerification checkHasVerification={true} checkUnAuthId={true} />}>
                        <Route
                            element={<AdditionalCardHolderPage />}
                            loader={(): null => {
                                AdditionalCardHolderDetailsPage.preload();
                                InsurancePage.preload();
                                return null;
                            }}
                            path={ERoutes.ADDITIONAL_CARD_HOLDER}
                        />
                        <Route
                            element={
                                <SmallLayout>
                                    <Outlet />
                                </SmallLayout>
                            }
                        >
                            <Route
                                element={<AdditionalCardHolderDetailsPage />}
                                loader={(): null => {
                                    InsurancePage.preload();
                                    return null;
                                }}
                                path={ERoutes.ADDITIONAL_CARD_HOLDER_DETAILS}
                            />
                        </Route>
                        <Route
                            element={<InsurancePage />}
                            loader={(): null => {
                                SetUpCompletePage.preload();
                                ReviewPage.preload();
                                return null;
                            }}
                            path={ERoutes.INSURANCE}
                        />
                    </Route>
                </Route>
            </Route>
            <Route
                element={
                    <SmallLayout>
                        <Outlet />
                    </SmallLayout>
                }
            >
                <Route
                    element={<ErrorPage />}
                    loader={async () => {
                        await deleteLocalStorage();
                        return null;
                    }}
                    path="/error"
                />
                {/* Ops Error */}
                <Route element={<OpsErrorPage />} path={ERoutes.OOPS_ERROR} />
            </Route>

            <Route
                element={
                    <SEOLayout path={SEOPaths.NOT_FOUND}>
                        <Outlet />
                    </SEOLayout>
                }
            >
                <Route element={<NotFoundPage />} path="/404" />
                <Route element={<NotFoundPage />} path="*" />
            </Route>
        </Route>
    </Route>
);

export const router = createBrowserRouter(createRoutesFromElements(elements));
