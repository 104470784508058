import TagManager from 'react-gtm-module';

import { CA_CENTRAL_1, US_EAST_1 } from 'config/constants';
import { ERoutes } from 'utils/constants';

import { DataLayerKeys, EGAEvent, TGAData, TGADefaultDataType } from './types';

export const pushRouteChangeDataLayer = (): void => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'gatsby-route-change',
        },
    });
};

export const pushDataLayer = (event: EGAEvent, data?: TGAData): void => {
    push(event, data);
};

export const pushUserIDToDataLayer = (userId: string): void => {
    TagManager.dataLayer({
        dataLayer: {
            [DataLayerKeys.userID]: userId?.replace(`${US_EAST_1}:`, '').replace(`${CA_CENTRAL_1}:`, ''),
        },
    });
};

const push = (event: EGAEvent, data?: TGAData): void => {
    let dataLayerData: TGADefaultDataType = {
        ...data,
        event,
    };

    if (event === EGAEvent.STEP_CHANGE || event === EGAEvent.APPLICATION_SUBMIT) {
        const { pathname, search } = window.location;
        const updatedPathname = pathname.replace(/^\/apply/, '');
        dataLayerData = {
            ...dataLayerData,
            vp_current_page_name: pathname === '/' ? ERoutes.ROOT : updatedPathname,
            vp_current_page_url: pathname + (search || ''),
        };
    }

    TagManager.dataLayer({
        dataLayer: dataLayerData,
    });
};
