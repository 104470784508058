import React, { useEffect, useState } from 'react';
import FairstoneStylesProvider from '@fairstone/ui/core/providers/FairstoneStylesProvider';

import { APP_CONTEXT } from 'config/constants';
import { useGetMerchantTheme } from 'services/theme';
import { AppContextEnum } from 'utils/context/types';

import walmartTheme from './walmartTheme.json';

const ThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { data } = useGetMerchantTheme();

    const [theming, setTheming] = useState(APP_CONTEXT && APP_CONTEXT === AppContextEnum.WMT ? walmartTheme : {});

    // Fix to stop the color change on load
    useEffect(() => {
        if (data) setTheming(data);
    }, [data]);

    useEffect(() => {
        const rootCss = document.querySelector(':root') as HTMLStyleElement;
        rootCss.style.setProperty('--button-outlined-hover-opacity', '1');
    }, []);

    return <FairstoneStylesProvider theme={theming}>{children}</FairstoneStylesProvider>;
};

export default ThemeProvider;
