export type TGADefaultDataType = {
    event: EGAEvent;
} & TGAData;

export type TGAData = Partial<{ [K in TDataLayerKeyType]: string | boolean | number | null }>;

export enum EGAEvent {
    APPLICATOIN_PARAMS_UPDATE = 'applicationParamsUpdate',
    ONFIDO_STATUS = 'onfidoStatus',
    STEP_CHANGE = 'step_change',
    APPLICATION_SUBMIT = 'application_submit',
}

export const DataLayerKeys = {
    additionalCardHolder: 'additionalCardHolder',
    altFlow: 'formVariant',
    application_decison: 'application_decison',
    application_flow: 'application_flow',
    applicationNumber: 'applicationNumber',
    balanceProtection: 'bpp',
    digitalIDScreen: 'digitalIDScreen',
    extendedWarranty: 'ewp',
    final_authentication_type: 'final_authentication_type',
    otpFailed: 'otpFailed',
    paperless: 'paperless',
    user_id: 'user_id',
    userID: 'userID',
    verification_type: 'verification_type',
    vp_current_page_name: 'vp_current_page_name',
    vp_current_page_url: 'vp_current_page_url',
} as const;

export type TDataLayerKeyType = (typeof DataLayerKeys)[keyof typeof DataLayerKeys];
